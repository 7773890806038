import { message } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

function FeedbackForm() {
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
  const encryptedToken = Cookies.get('token');
 const token = encryptedToken ? CryptoJS.AES.decrypt(encryptedToken, encryptionKey).toString(CryptoJS.enc.Utf8) : null;
  const encryptedUser = Cookies.get('user');
 const user = encryptedUser ? JSON.parse(CryptoJS.AES.decrypt(encryptedUser , encryptionKey).toString(CryptoJS.enc.Utf8)):null ;
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    message: '',
    
  });

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const Feedback = async (e) => {
   
    e.preventDefault();
    if (!user) {
      return toast.info("No user found. Please login to provide feedback."
      );
    }
   
    try {
      const config = {
        url: "/admin/feedback",
        baseURL: "https://parnetslink.xyz/api",
        method: "post",
        headers: { "Content-Type": "application/json" },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
        
          email: formData.email,
         
          name: formData.name,
          mobile: formData.phoneNumber,
          message: formData.message,
         
        },
      };

      const res = await axios(config);
      if (res.status === 200) {
     
        toast.info("Successfully Posted"
        );
      
        window.location.assign("/");
      }
    } catch (error) {
      console.error("Update failed", error);
     
    }
  };


  return (
    <form onSubmit={Feedback} className="feedback-form">
      <ToastContainer />
      <h2>Feedback</h2>
      <div className="form-group">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
      </div>
      
      <div className="form-group">
        <label htmlFor="email">Email Address</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
      </div>
      
      <div className="form-group">
        <label htmlFor="phoneNumber">Phone Number</label>
        <input
          type="tel"
          id="phoneNumber"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
        />
      </div>
      
      <div className="form-group">
        <label htmlFor="message">Message</label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="We take each customer feedback seriously & would love to hear from you. Please do spare a moment for us to improve our produce range, quality, service or any other aspect"
        />
      </div>
   
      
      <button type="submit" className="button1">THANK YOU</button>
    </form>
  );
}

export default FeedbackForm;