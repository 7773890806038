
import { initializeApp } from "firebase/app";
import { getMessaging ,getToken} from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyBn3eGlq2UU4Z5EVnCBOcKTy6UF2WdYGaw",
    authDomain: "abhinandhan-43620.firebaseapp.com",
    projectId: "abhinandhan-43620",
    storageBucket: "abhinandhan-43620.appspot.com",
    messagingSenderId: "674275225981",
    appId: "1:674275225981:web:8c57cdca0427003658dc14",
    measurementId: "G-82D0TPD4PS"
};
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
function requestPermission() {
  console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
      getToken(messaging, {
        vapidKey: "BBiWNyygG3eiMDLFIwTUdpNlY2Lhk_YC_XdDSFWMI0qhBEJ_s263UOC0zXzn_viyFfO8ywf44zyLMcIk8GLyl_U",
      })
        .then((currentToken) => {
          if (currentToken) {
            console.log("FCM Token: ", currentToken);
         
          } else {
            console.log("No registration token available. Request permission to generate one.");
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
    } else {
      console.log("Do not have permission!");
    }
  });
}

requestPermission();
export { app, messaging };
