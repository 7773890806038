import "./App.css";
import React, { useEffect, useRef, useState } from "react";
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import Pages from "./components/pages/Pages";
import "antd/dist/reset.css";

import { getToken } from "firebase/messaging";
import { messaging } from "./firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import axios from "axios";
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

function App() {
// Retrieve encrypted data from cookies
const encryptedToken = Cookies.get('token');
const encryptedUser = Cookies.get('user');
const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

if (!encryptedToken || !encryptedUser || !encryptionKey) {
  console.error('Missing encrypted data or encryption key');
}

// Decrypt the data
const token = encryptedToken ? CryptoJS.AES.decrypt(encryptedToken, encryptionKey).toString(CryptoJS.enc.Utf8) : null;
const user = encryptedUser ? JSON.parse(CryptoJS.AES.decrypt(encryptedUser, encryptionKey).toString(CryptoJS.enc.Utf8)) : null;


console.log("user",user);
  useEffect(() => {
    console.log("Setting up message listener");
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log("Message received:", payload);
      console.log("Attempting to show notification");
      if (Notification.permission === "granted") {
        const notificationTitle = payload.notification.title;
        const notificationOptions = {
          body: payload.notification.body,
          icon: "../img/success.png", 
        };
        console.log("Attempting to show notification111");
        new Notification(notificationTitle, notificationOptions);
      }
      try {
        toast.info(
          `${payload.notification.title}: ${payload.notification.body}`
        );
        console.log("Notification displayed successfully");
      } catch (error) {
        console.error("Error displaying notification:", error);
      }
    });

    return () => {
      console.log("Cleaning up message listener");
      unsubscribe();
    };
  }, []);
  const items = useSelector((state) => state.cart.items);
  const timeoutRef = useRef(null);
  const lastNotificationRef = useRef(null);
  const timeoutDuration = 10 * 60 * 1000; 
  const [notifiedState, setNotifiedState] = useState({
    cart: null,
    timestamp: null
  });

  const isCartEqual = (cart1, cart2) => {
    if (!cart1 || !cart2) return false;
    const normalizedCart1 = cart1.map(item => ({
      ...item,
      timestamp: undefined 
    }));
    
    const normalizedCart2 = cart2.map(item => ({
      ...item,
      timestamp: undefined
    }));
    
    return JSON.stringify(normalizedCart1) === JSON.stringify(normalizedCart2);
  };

  const canNotifyAgain = () => {
    if (!notifiedState.timestamp) return true;
    const timeSinceLastNotification = Date.now() - new Date(notifiedState.timestamp).getTime();
    const minimumInterval = 60 * 60 * 1000;
    
    return timeSinceLastNotification >= minimumInterval;
  };

  const notifyAdmin = async (cartData) => {

    if (isCartEqual(cartData, notifiedState.cart) && !canNotifyAgain()) {
      console.log('Skipping notification - similar cart was recently notified');
      return;
    }

    try {
      const response = await axios.post(
        'https://parnetslink.xyz/api/admin/abandoned-cart',
        {
          cart: cartData,
          user: user,
          timestamp: new Date(),
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      console.log('Admin notified of abandoned cart:', response.data);
      setNotifiedState({
        cart: cartData,
        timestamp: new Date()
      });
      lastNotificationRef.current = Date.now();
      
    } catch (error) {
      console.error('Error notifying admin:', error);
    }
  };

  useEffect(() => {
    if (items.length > 0 && user) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (!isCartEqual(items, notifiedState.cart) || canNotifyAgain()) {
        timeoutRef.current = setTimeout(() => {
          console.log("Cart is abandoned, notifying admin...");
          notifyAdmin(items);
        }, timeoutDuration);
      }
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [items, user, notifiedState]);

  return (
    <div className="App">
      <ToastContainer />
      <Pages />
    </div>
  );
}

export default App;
