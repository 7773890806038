import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

const ClubModal = ({ isOpen, onClose, onJoinClick }) => {
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
  const encryptedToken = Cookies.get('token');
 const token = encryptedToken ? CryptoJS.AES.decrypt(encryptedToken, encryptionKey).toString(CryptoJS.enc.Utf8) : null;
  const [banner, setBanner] = useState([]);

  const getBanner = async () => {
    try {
      const res = await axios.get(
        "https://parnetslink.xyz/api/admin/getallclubbanner", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setBanner(res.data?.clubbanner);
      }
    } catch (error) {
      console.error('Error fetching the banner:', error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      getBanner();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg shadow-xl w-full max-w-md mx-4 relative"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center p-4 border-b">
          <div className="flex items-center w-full justify-center">
            <span className="fas fa-crown mr-2" style={{ color: "#be343b" }}></span>
            <h2 className="text-xl font-semibold mt-2">Join Our Club!</h2>
          </div>
          <button
            onClick={onClose}
            className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 transition-colors"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

       <div style={{padding: "20px", textAlign: "center"}}> 
        {banner.slice(0, 1).map((e) => (
          <React.Fragment key={e._id}>
            <img
              src={`https://parnetslink.xyz/image/${e.clubbanner}`}
              alt="Join Our Club"
               loading="lazy"
              style={{ width: "100%", height: "auto", marginBottom: "20px" }}
            />
            <p style={{ fontSize: "16px", marginBottom: "20px" }}>{e.type}</p>
          </React.Fragment>
        ))}
         <button className="buttons" onClick={onJoinClick}>
            Join as a Club Member
          </button>
          </div>
      </div>
    </div>
  );
};

export default ClubModal;
